<template>
  <div class="table-responsive my-3">
    <table class="table border border-dark border-2">
      <thead class="border-1 border-dark">
        <tr>
          <th colspan="2">{{ I18n.t('total_price_trial') }}</th>
        </tr>
      </thead>
      <tbody class="border-1 border-dark">
        <!-- 方案 -->
        <template v-if="!lodash.isEmpty(this.selected_products)">
          <tr>
            <th colspan="2" class="border-bottom-0">
              <ul class="m-0">
                <li>{{ I18n.t('activerecord.models.product') }}</li>
              </ul>
            </th>
          </tr>
          <!-- 加購方案 & 附加方案 -->
          <tr v-for="(selected_product, selected_product_index) in selected_products" :key="selected_product_index" v-show="!isDeleted(selected_product)">
            <input type="hidden" :name="`add_on_order[add_on_product_records_attributes][${selected_product_index}][id]`" v-model="selected_product.id">
            <input type="hidden" :name="`add_on_order[add_on_product_records_attributes][${selected_product_index}][number_of_day]`" v-model="selected_product.number_of_day">
            <input type="hidden" :name="`add_on_order[add_on_product_records_attributes][${selected_product_index}][quantity]`" v-model="selected_product.quantity">
            <input type="hidden" :name="`add_on_order[add_on_product_records_attributes][${selected_product_index}][product_id]`" v-model="selected_product.product_id">
            <input type="hidden" :name="`add_on_order[add_on_product_records_attributes][${selected_product_index}][price]`" v-model="selected_product.price">
            <input type="hidden" :name="`add_on_order[add_on_product_records_attributes][${selected_product_index}][price_before_multiplied_by_quantity]`" v-model="selected_product.per_quantity_price">
            <input type="hidden" :name="`add_on_order[add_on_product_records_attributes][${selected_product_index}][expired_at]`" v-model="selected_product.expired_at">
            <input type="hidden" :name="`add_on_order[add_on_product_records_attributes][${selected_product_index}][_destroy]`" v-model="selected_product._destroy">
            <th class="border-bottom-0">
              <div class="mb-3 ps-5">
                <p class="mb-0">{{ selected_product.product.name }}</p>
                <template v-if="selected_product.number_of_day && selected_product.quantity">
                  <p class="mb-0">{{ I18n.t('add_on_days', locale_scope) }} * {{ `${selected_product.number_of_day}${I18n.t('number_of_day', locale_scope)}(${order.open_at} ~ ${selected_product.expired_at})` }}</p>
                  <p class="mb-0"> {{ I18n.t('exchange_rate') }} </p>
                  <p class="mb-0">{{ I18n.t('quantity', locale_scope) }}</p>
                </template>
              </div>
            </th>
            <td class="align-bottom text-end border-bottom-0">
              <div class="mb-3">
                <p class="mb-0">${{ lodash.toNumber(selected_product.product.price) }} USD</p>
                <template v-if="selected_product.number_of_day && selected_product.quantity">
                  <p class="mb-0">{{ selected_product.number_of_day }} * ({{ lodash.toNumber(selected_product.product.price) }}/{{ selected_product.valuation_days }})</p>
                  <p class="mb-0">*{{ exchange_rate }} = {{ numberConverter(selected_product.per_quantity_price, 2) }} </p>
                  <p class="mb-0">*{{ selected_product.quantity }}</p>
                </template>
              </div>
            </td>
          </tr>
          <!-- 方案總價 -->
          <tr>
            <th class="border-bottom-0">
              <p class="mb-0 ps-3">{{ I18n.t('product_price', locale_scope) }}</p>
            </th>
            <input type="hidden" v-model="order.product_price" name="add_on_order[product_price]">
            <td class="align-bottom text-end border-bottom-0">
              =<span class="mb-0 text-decoration-underline"> {{ numberConverter(order.product_price, 2) }}</span>
            </td>
          </tr>
        </template>

        <!-- 其他 -->
        <template v-if="order.product_discount != 0">
          <tr>
            <th colspan="2" class="border-bottom-0 border-dark border-top">
              <ul class="m-0">
                <li>{{ I18n.t('activerecord.models.extra_item') }}</li>
              </ul>
            </th>
          </tr>
          <tr class="mb-3">
            <th class="py-0 border-bottom-0">
              <!-- 方案折扣 -->
              <div class="ps-5"><p class="mb-0">{{ I18n.t('product_discount', locale_scope) }}</p></div>
            </th>
            <td class="py-0 align-bottom text-end border-bottom-0">
              <!-- 顯示負數 -->
              <span>{{ numberConverter(order.product_discount * -1) }}</span>
            </td>
          </tr>
          <tr>
            <th class="border-bottom-0">
              <!-- 其他總價，目前僅有方案折扣，所以直接拿來用，若日後有新增需再修改 -->
              <span class="ps-3">{{ I18n.t('extra_item_fee', locale_scope) }}</span>
            </th>
            <td class="align-bottom text-end border-bottom-0">
              <!-- 顯示負數 -->
              =<span class="text-decoration-underline"> {{ numberConverter(order.product_discount * -1) }}</span>
            </td>
          </tr>
        </template>

        <!-- 稅前金額&稅 -->
        <template v-if="order.tax_fee > 0">
          <tr>
            <th class="ps-4 border-top border-dark border-bottom-0">{{ I18n.t('price_before_tax', locale_scope) }}</th>
            <input type="hidden" v-model="order.price_before_tax" name="add_on_order[price_before_tax]">
            <td class="align-bottom text-end border-top border-dark border-bottom-0">
              {{ numberConverter(order.price_before_tax) }}
            </td>
          </tr>
          <tr>
            <th class="ps-4">{{ I18n.t('tax') }}</th>
            <input type="hidden" v-model="order.tax_fee" name="add_on_order[tax_fee]">
            <td class="align-bottom text-end">
              * {{ taxes[order.region]*100 }}% = <span class="text-decoration-underline">{{ numberConverter(order.tax_fee) }}</span>
            </td>
          </tr>
        </template>

        <!-- Total -->
        <tr class="border-top border-dark">
          <th class="ps-4">Total</th>
          <td class="align-bottom text-end">
            <span class="text-decoration-double-underline">{{ numberConverter(order.price) }}</span>
            <input type="hidden" v-model="order.price" name="add_on_order[price]">
            <p class="text-danger" v-if="isTw() && order.price >= 200000"><b>綠界金流限制訂單金額不得大於或等於20萬</b></p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import numberToCurrency from '../../orders/numberToCurrency';

  export default {
    props: {
      exchange_rate: {
        type: [Number, String],
        required: true
      },
      order: {
        type: Object,
        required: true
      },
      selected_products: {
        type: Array
      }
    },
    data() {
      return {
        I18n,
        lodash,
        locale_scope: {
          scope: 'activerecord.attributes.add_on_order'
        },
        round_precision: {
          tw: 0,
          hk: 2
        },
        taxes: {
          tw: 0.05,
          hk: 0
        }
      }
    },
    methods: {
      numberConverter(price, precision) {
        const region = this.order.region
        let round_precision = precision || this.round_precision[region]

        return numberToCurrency(region, price, round_precision)
      },
      isTw() {
        return this.order.region === 'tw'
      },
      isDeleted(product) {
        return product._destroy === '-1'
      }
    }
  }
</script>