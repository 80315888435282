<template>
  <div class="table-responsive">
    <!-- support_plan -->
    <ValidationProvider :rules="`required|oneOf:${support_plans.map(support_plan => support_plan.id).join(',')}`" :immediate="immediate_validate" :name="I18n.t('activerecord.models.support_plan')" v-slot="{ errors }">
    <table class="table table-striped table-bordered table-support caption-top">
      <caption class="fw-bold text-dark">{{ I18n.t('activerecord.models.support_plan') }}*</caption>
      <thead class="border-dark border-2">
        <tr>
          <th scope="col" colspan="2" class="text-center">
            <span v-html="I18n.t('support_plan.title')"></span><br>
            <small>{{ I18n.t('support_plan.comment') }}</small>
          </th>
          <th scope="col" class="text-center" v-for="(support_plan, support_plan_index) in support_plans" :key="support_plan_index">
            {{ support_plan.name }} <br/>
            <small v-if="support_plan.comment">*{{ support_plan.comment }}</small>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr >
          <th scope="row" rowspan="4">{{ I18n.t('name', item_locale_scope) }}</th>
          <th scope="row">{{ I18n.t('one_on_one_consult.name', item_locale_scope) }}</th>
          <td>V</td>
          <td>V</td>
          <td>V</td>
        </tr>
        <tr>
          <th scope="row">
            {{ I18n.t('account_activate.name', item_locale_scope) }}<br>
            <small class="fw-normal" v-html="I18n.t('account_activate.comment', item_locale_scope)"></small>
          </th>
          <td>V</td>
          <td>V</td>
          <td>V <br>
            <small class="fw-normal" v-html="I18n.t('account_activate.level.pro.comment', item_locale_scope)"></small>
          </td>
        </tr>
        <tr>
          <th scope="row">
            {{ I18n.t('knowledge_base.name', item_locale_scope) }}<br>
            <small class="fw-normal" v-html="I18n.t('knowledge_base.comment', item_locale_scope)"></small>
          </th>
          <td> - </td>
          <td> - </td>
          <td>V</td>
        </tr>
        <tr>
          <th scope="row">
            {{ I18n.t('ticket_5x8.name', item_locale_scope) }}<br>
            <small class="fw-normal" v-html="I18n.t('ticket_5x8.comment', item_locale_scope)"></small>
          </th>
          <td>
            <span v-html="I18n.t('ticket_5x8.level.account.name', item_locale_scope)"></span><br>
            <small class="fw-normal" v-html="I18n.t('ticket_5x8.level.account.comment', item_locale_scope)"></small>
          </td>
          <td>V</td>
          <td>V</td>
        </tr>
        <tr>
          <th scope="row" rowspan="5">{{ I18n.t('name', sla_locale_scope) }}</th>
          <th scope="row">
            {{ I18n.t('ticket_response_time.name', sla_locale_scope) }}<br>
            <small class="fw-normal" v-html="I18n.t('ticket_response_time.comment', sla_locale_scope)"></small>
          </th>
          <td> - </td>
          <td>{{ I18n.t('ticket_response_time.level.standard.name', sla_locale_scope) }}</td>
          <td>{{ I18n.t('ticket_response_time.level.pro.name', sla_locale_scope) }}</td>
        </tr>
        <tr>
          <th scope="row">{{ I18n.t('ticket_close_time.name', sla_locale_scope) }}</th>
          <td> - </td>
          <td>{{ I18n.t('ticket_close_time.level.standard.name', sla_locale_scope) }}</td>
          <td>{{ I18n.t('ticket_close_time.level.pro.name', sla_locale_scope) }}</td>
        </tr>
        <tr>
          <th scope="row">
            {{ I18n.t('response_call_5x8.name', sla_locale_scope) }}<br>
            <small class="fw-normal" v-html="I18n.t('response_call_5x8.comment', sla_locale_scope)"></small>
          </th>
          <td> - </td>
          <td> - </td>
          <td>V</td>
        </tr>
        <tr>
          <th scope="row">
            {{ I18n.t('anydesk.name', sla_locale_scope) }}<br>
            <small class="fw-normal" v-html="I18n.t('anydesk.comment', sla_locale_scope)"></small>
          </th>
          <td> - </td>
          <td> - </td>
          <td>V</td>
        </tr>
        <tr>
          <th colspan="4" class="text-left">
            <small class="fw-normal">{{ I18n.t('comment', sla_locale_scope) }}</small>
          </th>
        </tr>
      </tbody>
      <tfoot class="">
        <tr>
          <th scope="row" colspan="2" class="text-center">
            {{ I18n.t("support_plan.price.name") }}
          </th>
          <td>
            $ 2,990/{{ I18n.t("support_plan.price.unit.count") }}
          </td>
          <td>
            $ 4,990/{{ I18n.t("support_plan.price.unit.year") }}
          </td>
          <td>
            $ 6,990/{{ I18n.t("support_plan.price.unit.year") }}
          </td>
        </tr>
        <tr class="border-dark border-2">
          <th scope="row" colspan="2" class="text-center">{{ I18n.t('helpers.select.prompt') }}</th>
          <td class="border-start border-end" :class="{'border-primary border-2': order.support_plan_id == support_plan.id}" v-for="(support_plan, support_plan_index) in support_plans" :key="support_plan_index" @click="selectSupport(support_plan.id)">
            <div class="form-group d-flex justify-content-center">
              <div class="form-check">
                <input type="radio" :value="support_plan.id" name="order[support_plan_id]" :id="`order_support_plan_id_${support_plan.id}`" class="form-check-input check_boxes optional" :ref="`order_support_plan_id_${support_plan.id}`" v-model="order.support_plan_id" >
                <label :for="`order_support_plan_id_${support_plan.id}`" class="form-check-label collection_check_boxes">{{ I18n.t('choose') }}</label>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
    <p><small class="text-danger">{{ errors[0] }}</small></p>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object
    },
    support_plans: {
      type: Array
    },
    immediate_validate: {
      type: Boolean
    }
  },
  data(){
    return {
      lodash,
      I18n,
      item_locale_scope: {
        scope: 'support_plan.plan_item'
      },
      sla_locale_scope: {
        scope: 'support_plan.plan_sla'
      },
    }
  },
  methods: {
    selectSupport(support_plan_id){
      this.order.support_plan_id = support_plan_id
    },
    isRenew(){
      return this.order.category == 'renew'
    }
  }
}
</script>

<style>

</style>