<template>
  <tr>
      <th class="ps-5 align-middle">
        {{ extra_item.name }}
      </th>
      <td class="align-bottom text-end">
        <template v-if="canEditExtraItem">
          <input type="hidden" :name="`order[extra_items_attributes][${extra_item.id}][id]`" v-model="extra_item.id">
          <input type="hidden" :name="`order[extra_items_attributes][${extra_item.id}][name]`" v-model="extra_item.name">
          <ValidationProvider rules="required" :name="I18n.t('activerecord.attributes.extra_item.price')" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <div class="input-group d-inline-flex w-auto">
              <span class="input-group-text">-</span>
              <input type="number" class="form-control text-end" :name="`order[extra_items_attributes][${extra_item.id}][price]`" :class="classes" v-model.trim="extra_item.price" inputmode="numeric" @input="setExtraIPrice">
            </div>
            <p class="mb-0"><small class="text-danger">{{ errors[0] }}</small></p>
        </ValidationProvider>
        </template>
        <template v-else>
          {{(extra_item.category == 'discount') ? ' - ' : ''}}{{ numberToCurrency(extra_item.price) }}
        </template>
      </td>
    </tr>
</template>

<script>
import numberToCurrency from './numberToCurrency';
export default {
  props: {
    order: {
      type: Object
    },
    extra_item: {
      type: Object
    },
    canEditExtraItem: {
      type: Boolean,
      default: false
    },
    immediate_validate: {
      type: Boolean
    }
  },
  data(){
    return {
      I18n,
      lodash
    }
  },
  methods: {
    setExtraIPrice(){
      this.$emit('setExtraIPrice')
    },
    numberToCurrency(price){
      const region = this.order.region
      const precision = region == 'tw' ? 0 : 2
      return numberToCurrency(region, price, precision)
    }
  }
}
</script>

<style>

</style>