<template>
  <order-form :order_form=admin_order_form :user_section="false"></order-form>
</template>

<script>
import OrderForm from '../../orders/OrderForm'

export default {
  components: {
    OrderForm
  },
  props: {
    admin_order_form: {
      type: Object
    }
  }
}
</script>

<style>

</style>