<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
    <div class="d-md-flex justify-content-between align-items-end mb-3">
      <h5>{{ I18n.t('welcome') }}</h5>
      <p class="mb-2">{{ I18n.t('exchange_rate_prefix') }} USD 1:{{ exchangeRate() }}</p>
      <input type="hidden" name="add_on_order[exchange_rate]" v-model="exchange_rate">
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <!-- domain -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('domain', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider vid="domain" rules="required" :name="I18n.t('domain', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="add_on_order[domain]" :class="classes" v-model.trim="add_on_order.domain" inputmode="url">
            <small class="form-text text-muted" v-html="I18n.t('simple_form.hints.order.domain')"></small>
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>
        <!-- company_name -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('company_name', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('company_name', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="add_on_order[company_name]" :class="classes" v-model.trim="add_on_order.company_name">
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <!-- open_at -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('open_at', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('open_at', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <flat-pickr
              v-model="add_on_order.open_at"
              :config="open_at_config"
              class="form-control form-input"
              :class="classes"
              :placeholder="I18n.t('helpers.select.prompt')"
              name="add_on_order[open_at]">
            </flat-pickr>
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- contact -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('contact', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('contact', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="add_on_order[contact]" :class="classes" v-model.trim="add_on_order.contact">
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- contact_phone -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('contact_phone', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('contact_phone', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="add_on_order[contact_phone]" :class="classes" v-model.trim="add_on_order.contact_phone" inputmode="tel">
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- contact_email -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('contact_email', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('contact_email', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="email" class="form-control" name="add_on_order[contact_email]" :class="classes" v-model.trim="add_on_order.contact_email" inputmode="email">
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>

    <!-- workspace 方案區塊 -->
    <div class="form-group my-3 row no-gutters">
      <div class="card-body d-flex justify-content-between align-items-center">
        <label class="col col-form-label fw-bold">
          {{ I18n.t('workspace_product', locale_scope) }}
        </label>
        <button type="button" class="btn btn-outline-primary" @click.prevent="addOnWorkspaceProduct">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="card-body" >
        <table class="table" v-if="selected_add_on_products.filter(({ _destroy }) =>  _destroy != '-1').length > 0">
          <thead class="table-secondary">
            <tr>
              <th scope="col">{{ I18n.t('workspace_product', locale_scope) }}</th>
              <th scope="col">{{ I18n.t('choose_number_of_day', locale_scope) }}</th>
              <th scope="col">{{ I18n.t('quantity', locale_scope) }}</th>
              <th scope="col">{{ I18n.t('expired_at', locale_scope) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(workspace_product, workspace_product_index) in selected_add_on_products" :key="workspace_product_index" v-show="!isDeleted(workspace_product)">
              <td class="col-6">
                <!-- 選擇 workspace 方案 -->
                <ValidationProvider rules="required" :name="I18n.t('workspace_product', locale_scope)" immediate v-slot="{ errors }">
                <multiselect :options="add_on_products" :custom-label="productWithName" :placeholder="I18n.t('select')" v-model="workspace_product.product" @input="setSelectedProducts">
                </multiselect>
                <p><small class="text-danger">{{ errors[0] }}</small></p>
                </ValidationProvider>
              </td>
              <td class="col-2">
                <!-- number_of_day -->
                <ValidationProvider rules="required" :name="I18n.t('choose_number_of_day', locale_scope)" immediate v-slot="{ errors }">
                <input type="number" min="1" class="form-control" v-model.trim="workspace_product.number_of_day" @keyup="setSelectedProducts">
                <p><small class="text-danger">{{ errors[0] }}</small></p>
                </ValidationProvider>
              </td>
              <td class="col-2">
                <!-- quantity -->
                <ValidationProvider rules="required" :name="I18n.t('quantity', locale_scope)" immediate v-slot="{ errors }">
                <input type="hidden" v-model="workspace_product.quantity">
                <multiselect :placeholder="I18n.t('select')" v-model="workspace_product.quantity" :options="quantity_range" :allow-empty="false" :showLabels="false" @input="setSelectedProducts"></multiselect>
                <p><small class="text-danger">{{ errors[0] }}</small></p>
                </ValidationProvider>
              </td>
              <td class="col-2">
                <!-- expired_at -->
                <ValidationProvider rules="required" :name="I18n.t('expired_at', locale_scope)" immediate v-slot="{ errors }">
                <flat-pickr
                  v-model="workspace_product.expired_at"
                  :config="expired_at_config"
                  class="form-control form-input"
                  :placeholder="I18n.t('helpers.select.prompt')"
                  @input="setSelectedProducts">
                </flat-pickr>
                <p><small class="text-danger">{{ errors[0] }}</small></p>
                </ValidationProvider>
              </td>
              <td>
                <!-- 刪除 btn -->
                <button type="button" class="ml-2 btn btn-danger" @click.prevent="deleteWorkspaceProduct(workspace_product_index)">
                  <input type="hidden" v-model="workspace_product._destroy">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- additional 方案區塊 -->
    <div class="form-group my-3 row no-gutters">
      <div class="card-body d-flex justify-content-between align-items-center">
        <label class="col col-form-label fw-bold">
          {{ I18n.t('workspace_additional_product', locale_scope) }}
        </label>
        <button type="button" class="btn btn-outline-primary" @click.prevent="addOnAdditionalProduct">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <div class="card-body" >
        <table class="table" v-if="selected_additional_products.filter(({ _destroy }) =>  _destroy != '-1').length > 0">
          <thead class="table-secondary">
            <tr>
              <th scope="col">{{ I18n.t('workspace_additional_product', locale_scope) }}</th>
              <th scope="col">{{ I18n.t('choose_number_of_day', locale_scope) }}</th>
              <th scope="col">{{ I18n.t('quantity', locale_scope) }}</th>
              <th scope="col">{{ I18n.t('expired_at', locale_scope) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(additional_product, additional_index) in selected_additional_products" :key="additional_index" v-show="!isDeleted(additional_product)">
              <td class="col-6">
                <!-- additional 方案 -->
                <ValidationProvider rules="required" :name="I18n.t('workspace_additional_product', locale_scope)" immediate v-slot="{ errors }">
                <multiselect :options="additional_products" :custom-label="productWithName" :placeholder="I18n.t('select')" v-model="additional_product.product" @input="setSelectedProducts">
                </multiselect>
                <p><small class="text-danger">{{ errors[0] }}</small></p>
                </ValidationProvider>
              </td>
              <td class="col-2">
                <!-- number_of_day -->
                <ValidationProvider rules="required" :name="I18n.t('choose_number_of_day', locale_scope)" immediate v-slot="{ errors }">
                <input type="number" min="1" class="form-control" v-model.trim="additional_product.number_of_day" @keyup="setSelectedProducts">
                <p><small class="text-danger">{{ errors[0] }}</small></p>
                </ValidationProvider>
              </td>
              <td class="col-2">
                <!-- quantity -->
                <ValidationProvider rules="required" :name="I18n.t('quantity', locale_scope)" immediate v-slot="{ errors }">
                <input type="hidden" v-model="additional_product.quantity">
                <multiselect :placeholder="I18n.t('select')" v-model="additional_product.quantity" :options="quantity_range" :allow-empty="false" :showLabels="false" @input="setSelectedProducts"></multiselect>
                <p><small class="text-danger">{{ errors[0] }}</small></p>
                </ValidationProvider>
              </td>
              <td class="col-2">
                <!-- expired_at -->
                <ValidationProvider rules="required" :name="I18n.t('expired_at', locale_scope)" immediate v-slot="{ errors }">
                <flat-pickr
                  v-model="additional_product.expired_at"
                  :config="expired_at_config"
                  class="form-control form-input"
                  :placeholder="I18n.t('helpers.select.prompt')"
                  @input="setSelectedProducts">
                </flat-pickr>
                <p><small class="text-danger">{{ errors[0] }}</small></p>
                </ValidationProvider>
              </td>
              <td>
                <!-- 刪除 btn -->
                <button type="button" class="ml-2 btn btn-danger" @click.prevent="deleteAdditionalProduct(additional_index)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- 方案折扣區塊 -->
    <div class="my-3">
      <div class="d-flex align-items-center">
        <label class="form-label fw-bold">
          {{ I18n.t('activerecord.models.extra_item') }}
        </label>
      </div>
      <div class="mb-3 row align-items-center">
        <div class="col-auto">
          <div class="form-group">
            <label class="col-form-label">
              {{ I18n.t('name', locale_scope) }}
            </label>
            <input type="text" class="form-control" readonly :value="I18n.t('product_discount', locale_scope)">
          </div>
        </div>
        <div class="col-auto">
          <div class="form-group">
            <label class="col-form-label">
              {{ I18n.t('price', locale_scope) }}
            </label>
            <ValidationProvider rules="required" :name="I18n.t('price', locale_scope)" :immediate="false" v-slot="{ errors, classes }">
            <input type="number" class="form-control" name="add_on_order[product_discount]" step="0.01" min="0" inputmode="numeric" :class="classes" v-model.trim="add_on_order.product_discount" @keyup="calTaxAndTotal()">
            <p class="mb-0"><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>

    <!-- 金額試算區塊 -->
    <total-price-trial :exchange_rate="exchange_rate" :order="add_on_order" :selected_products="selected_products"></total-price-trial>

    <div class="row justify-content-center my-3">
      <div class="col-12 col-sm-8">
        <div class="text-center mb-3">
          <button v-if="isNew()" class="btn btn-success" :disabled="invalid" :data-confirm="I18n.t('create_confirm', locale_scope)">{{ I18n.t('create', locale_scope) }}</button>
          <button v-else class="btn btn-success" :disabled="invalid" :data-confirm="I18n.t('update_confirm', locale_scope)">{{ I18n.t('update', locale_scope) }}</button>
        </div>
      </div>
    </div>
    </ValidationObserver>
  </div>
</template>
<script>
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw.js';
  import Multiselect from 'vue-multiselect';

  export default {
    components: {
      flatPickr,
      Multiselect
    },
    props: {
      add_on_order_form: {
        type: Object,
        required: true
      },
      action_name: {
        type: String,
        default: 'new'
      }
    },
    data() {
      return {
        I18n,
        locale_scope: {
          scope: 'activerecord.attributes.add_on_order'
        },
        immediate_validate: lodash.includes(['create', 'update'], this.action_name),
        add_on_order: this.add_on_order_form.add_on_order,
        add_on_products: this.add_on_order_form.add_on_products,
        additional_products: this.add_on_order_form.additional_products,
        exchange_rate: lodash.toNumber(this.add_on_order_form.add_on_order.exchange_rate),
        open_at_config: {
          locale: I18n.locale === 'zh-TW' ? MandarinTraditional : 'en',
          defaultDate: this.add_on_order_form.add_on_order.open_at,
          altFormat: 'Y-m-d',
          minDate: dayjs().format(),
          maxDate: dayjs().add(2, 'month').format()
        },
        selected_add_on_products: this.add_on_order_form.selected_add_on_products,
        selected_additional_products: this.add_on_order_form.selected_additional_products,
        // 小額加購上限 20 U
        quantity_range: lodash.range(1, 21),
        selected_products: [],
        round_precision: {
          tw: 0,
          hk: 2
        },
        taxes: {
          tw: 0.05,
          hk: 0
        },
        expired_at_config: {
          locale: I18n.locale === 'zh-TW' ? MandarinTraditional : 'en',
          altFormat: 'Y-m-d',
          minDate: dayjs().format()
        }
      }
    },
    mounted() {
      this.add_on_order.open_at = this.formatTimestamp(this.add_on_order.open_at)
      this.setSelectedProducts()
    },
    methods: {
      exchangeRate() {
        if (this.add_on_order.region === 'tw') {
          return `${this.exchange_rate} TWD`
        }
      },
      isNew() {
        return this.add_on_order.id === null
      },
      addOnWorkspaceProduct() {
        const new_workspace_product = {
          id: null,
          expired_at: null,
          product_id: null,
          quantity: null,
          number_of_day: null,
          price: null,
          _destroy: null
        }
        this.selected_add_on_products = [
          ...this.selected_add_on_products, new_workspace_product
        ]
      },
      addOnAdditionalProduct() {
        const new_additional_product = {
          id: null,
          expired_at: null,
          product_id: null,
          quantity: null,
          number_of_day: null,
          price: null,
          _destroy: null
        }
        this.selected_additional_products = [
          ...this.selected_additional_products, new_additional_product
        ]
      },
      isDeleted(product) {
        return product._destroy == '-1'
      },
      productWithName(product) {
        let price = lodash.toNumber(product.price)
        let suffix = I18n.t(`valuation_name.${product.valuation_name}`, this.locale_scope)

        return `${product.name}($${price} USD/${suffix})`
      },
      deleteWorkspaceProduct(index) {
        this.selected_add_on_products[index]._destroy = '-1'
        this.setSelectedProducts()
      },
      deleteAdditionalProduct(index) {
        this.selected_additional_products[index]._destroy = '-1'
        this.setSelectedProducts()
      },
      setSelectedProducts() {
        // 把加購方案和附加方案整理好，傳入子元件
        let add_ons = this.selected_add_on_products.filter(({ product }) => product)
        let additionals = this.selected_additional_products.filter(({ product }) => product)

        if(!lodash.isEmpty(add_ons) || !lodash.isEmpty(additionals)) {
          let all = add_ons.concat(additionals)
          let selected_products = all.map((selected_product) => {
            // 放入計算好的方案授權數單價、加購價格、計價天數、product_id
            let per_quantity_price = this.calProductPriceByQuantity(selected_product)
            let price = this.calProductPrice(selected_product, per_quantity_price)
            let valuation_days = selected_product.product.valuation_days
            let product_id = selected_product.product.id

            selected_product = {
              ...selected_product,
              product_id: product_id,
              valuation_days: valuation_days,
              per_quantity_price: per_quantity_price,
              price: price
            }

            return selected_product
          })

          this.selected_products = selected_products

        } else {

          this.selected_products = []
          this.add_on_order.product_discount = 0
        }

        this.$nextTick(this.calTotalProductPrice())
      },
      calProductPriceByQuantity(selected_add_on) {
        let unit_price = lodash.toNumber(selected_add_on.product.price)
        let valuation_days = lodash.toNumber(selected_add_on.product.valuation_days)

        let per_quantity_price = selected_add_on.number_of_day * unit_price / valuation_days * this.exchange_rate

        per_quantity_price = lodash.toNumber(per_quantity_price)

        return lodash.round(per_quantity_price, 2)
      },
      calProductPrice(selected_add_on, per_quantity_price) {
        return lodash.toNumber(selected_add_on.quantity * per_quantity_price)
      },
      calTotalProductPrice() {
        let valid_items = this.selected_products.filter(({ _destroy }) => _destroy !== '-1')

        this.add_on_order.product_price = lodash.sumBy(valid_items, ({price}) => {
          return lodash.round(price, this.round_precision[this.add_on_order.region])
        })
        this.calTaxAndTotal()
      },
      calTaxAndTotal() {
        this.$nextTick(() => {
          let round_precision = this.round_precision[this.add_on_order.region]

          this.add_on_order.price_before_tax = lodash.round(this.add_on_order.product_price - this.add_on_order.product_discount, round_precision)
          this.add_on_order.tax_fee = lodash.round(this.add_on_order.price_before_tax * this.taxes[this.add_on_order.region], round_precision)
          this.add_on_order.price = this.add_on_order.price_before_tax + this.add_on_order.tax_fee
        })
      },
      formatTimestamp(timestamp) {
        if(!this.isNew()) {
          return dayjs(timestamp).format('YYYY-MM-DD')
        }
      }
    }
  }
</script>