import Noty from 'noty';
document.addEventListener("DOMContentLoaded", function() {
  Noty.overrideDefaults({
    theme: "bootstrap-v4",
    timeout: 3000,
    visibilityControl: true
  });

  if (gon.noty_message){
    gon.noty_message.forEach(({ type, text }) => {
      new Noty({
        type,
        text,
      }).show();
    });
  }
});
window.Noty = Noty;
