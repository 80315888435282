<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab :title="I18n.t('activerecord.models.order')" active>
        <admin-order-index :url="admin_order_index_url"></admin-order-index>
      </b-tab>
      <b-tab :title="I18n.t('activerecord.models.add_on_order')">
        <admin-add-on-order-index :url="admin_add_on_order_index_url"></admin-add-on-order-index>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import AdminOrderIndexVue from '../orders/AdminOrderIndex.vue'
  import AdminAddOnOrderIndexVue from '../add_on_orders/AdminAddOnOrderIndex.vue'

  export default {
    components: {
      AdminOrderIndexVue,
      AdminAddOnOrderIndexVue
    },
    props: {
      admin_order_index_url: {
        type: String
      },
      admin_add_on_order_index_url: {
        type: String
      }
    },
    data() {
      return {
        I18n
      }
    },
  }
</script>