import lodash from 'lodash'
const numberToCurrency = (region, number, precision) => {
  let currency = "USD";
  switch (region) {
    case "tw":
      currency = "TWD";
      break;
    case "hk":
      currency = "HKD";
      break;
    default:
      break;
  }
  let result_number = number;
  if(result_number < 0){
    result_number *= -1
  }
  return `${number < 0 ? '- ' :  ''}$${lodash.round(result_number, precision).toLocaleString()} ${currency}`;
};

export default numberToCurrency;