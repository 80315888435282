import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import calender from "dayjs/plugin/calendar";
import dayjsBusinessDays from "dayjs-business-days";
require("dayjs/locale/zh-tw");
require("dayjs/locale/en");
const locale = lodash.lowerCase(gon.locale);
dayjs.locale(locale);
dayjs.extend(AdvancedFormat);
dayjs.extend(CustomParseFormat);
dayjs.extend(calender);
dayjs.extend(dayjsBusinessDays);
window.dayjs = dayjs;

