import flatpickr from 'flatpickr'
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index.js";

import "flatpickr/dist/l10n/zh-tw"
document.addEventListener('DOMContentLoaded', function(){
  flatpickr.localize(flatpickr.l10ns.zh_tw);
  flatpickr(".datepicker", {
  });
  flatpickr(".datetimepicker", {
    enableTime: true,
    time_24hr: true
  });
  flatpickr(".monthpicker", {
    plugins: [
      new monthSelectPlugin({
        shorthand: true,
        dateFormat: "Y-m",
      }),
    ],
  });
})
