<template>
  <div data-html2canvas-ignore>
    <button class="btn btn-secondary to-image" type="button">{{ I18n.t('order.download_order') }}</button>
    <b-modal id="canvas-modal" center scrollable hide-footer >
      <img class="img-fluid" ref='canvas-wrapper'>
      <div class="text-center">
        <a href="" ref="canvas-link">{{ I18n.t('order.download_order') }}</a>
      </div>
    </b-modal>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
  props: {
    order: {
      type: Object
    }
  },
  data(){
    return {
      I18n
    }
  },
  mounted(){
    const vm = this
    document.querySelector('.to-image').addEventListener('click', function(){
      // 盡量讓所有device圖片一致
      html2canvas(document.body, {
        width: 1920,
        height: 1080,
        windowWidth: 1920,
        windowHeight: 1080,
        scale: 2
      }).then(function(canvas) {
        vm.$bvModal.show('canvas-modal')
        vm.$nextTick(() => {
          vm.$refs['canvas-wrapper'].src = canvas.toDataURL()
          vm.$refs['canvas-wrapper'].alt = vm.order.no

          vm.$refs['canvas-link'].href = canvas.toDataURL()
          vm.$refs['canvas-link'].download = `${vm.order.no}.png`;
          vm.$refs['canvas-link'].target = '_blank'
        })
      });
    })
  }
}
</script>

<style>

</style>