<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "10", "desc" ]]' >
      <thead>
        <tr>
          <th scope="col" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('region', locale_scope) }}
          </th>
          <th scope="col" data-name="category" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('category', locale_scope) }}
          </th>
          <th scope="col" data-name="company_name" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('company_name', locale_scope) }}
          </th>
          <th scope="col" data-name="fresh_mark" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('fresh_mark', locale_scope) }}
          </th>
          <th scope="col" data-name="no" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('no', locale_scope) }}<br>金流序號
          </th>
          <th scope="col" data-name="product" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('product', locale_scope) }}
          </th>
          <th scope="col" data-name="quantity" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('quantity', locale_scope) }}
          </th>
          <th scope="col" data-name="month_amount" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('month_amount', locale_scope) }}
          </th>
          <th scope="col" data-name="price" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('price', locale_scope) }}
          </th>
          <th scope="col" data-name="status" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="created_at" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('created_at') }}
          </th>
          <th scope="col" data-name="netsuite_id" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('netsuite_id', locale_scope) }}
          </th>
          <th scope="col" data-name="invoice_id" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('invoice_id', locale_scope) }}
          </th>
          <th scope="col" data-name="" data-class-name="text-center align-middle" data-sortable="false" class="text-center">
            {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
  export default {
    props: {
      url: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        I18n,
        locale_scope: {
          scope: 'activerecord.attributes.order'
        },
        dataTable: null,
      }
    },
    mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: {
            url: this.url
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
          bDestroy: true
        }).columns.adjust();
    },
    methods: {
      reloadTable() {
        this.dataTable.ajax.reload()
      },
    },
  };
</script>