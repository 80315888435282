<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "6", "desc" ]]'>
      <thead>
        <tr>
          <th scope="col" data-name="region" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('region', locale_scope) }}
          </th>
          <th scope="col" data-name="company_name" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('company_name', locale_scope) }}
          </th>
          <th scope="col" data-name="no" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('no', locale_scope) }}<br>金流序號
          </th>
          <th scope="col" data-name="open_at" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('open_at', locale_scope) }}
          </th>
          <th scope="col" data-name="price" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('price', locale_scope) }}
          </th>
          <th scope="col" data-name="status" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="created_at" data-sortable="true" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('created_at') }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class="text-center">
            {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
  export default {
    props: {
      url: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        I18n,
        locale_scope: {
          scope: 'activerecord.attributes.add_on_order'
        },
        dataTable: null
      }
    },
    mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: {
            url: this.url
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).columns.adjust();
    },
    methods: {
      reloadTable() {
        this.dataTable.ajax.reload()
      },
    },
  }
</script>