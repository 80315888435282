import ClipboardJS from "clipboard";
document.addEventListener("DOMContentLoaded", function() {
  const clipboard = new ClipboardJS(".click-copy");
  clipboard.on("success", function(e) {
    new Noty({
      type: 'success',
      text: 'copied!',
    }).show();
    e.clearSelection();
  });
});