<template>
  <div class="my-3" >
      <div class="d-flex align-items-center">
        <h5 class="card-title mb-0">
          {{ I18n.t('activerecord.models.extra_item') }}
        </h5>
        <button class="btn btn-primary btn-sm" type="button" @click.prevent="newExtraItem()">
          {{ I18n.t('new') }}
        </button>
      </div>
      <div class="mb-3 row align-items-center" v-for="(extra_item, extra_item_index) in extra_items" :key="extra_item_index" v-show="!isDeleted(extra_item)">
        <div class="col">
          <input type="hidden" :name="`order[extra_items_attributes][${extra_item_index}][id]`" v-model="extra_item.id">
          <input type="hidden" :name="`order[extra_items_attributes][${extra_item_index}][_destroy]`" v-model="extra_item._destroy">
          <div class="form-group">
            <label class="col-form-label">
              {{ I18n.t('name', locale_scope) }}
            </label>
            <ValidationProvider rules="required" :name="I18n.t('name', locale_scope)" v-slot="{ errors, classes }" :immediate="false">
            <input type="text" class="form-control" :name="`order[extra_items_attributes][${extra_item_index}][name]`" :class="classes" v-model.trim="extra_item.name" @keyup="setExtraItems" :readonly="isFixedItem(extra_item)">
            <p class="mb-0"><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-auto">
          <div class="form-group">
            <label class="col-form-label">
              {{ I18n.t('category', locale_scope) }}
            </label>
            <select v-model="extra_item.category" :name="`order[extra_items_attributes][${extra_item_index}][category]`" :id="`extra_item_category${extra_item_index}`" class="form-select" @change="setExtraItems" :disabled="isFixedItem(extra_item)">
              <option :value="extra_item_category"  v-for="(extra_item_category, extra_item_category_index) in extra_item_categories" :key="extra_item_category_index">{{ I18n.t(`simple_form.options.extra_item.category.${extra_item_category}`) }}</option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label class="col-form-label">
              {{ I18n.t('price', locale_scope) }}
            </label>
            <ValidationProvider rules="required" :name="I18n.t('price', locale_scope)" :immediate="false" v-slot="{ errors, classes }">
            <input type="number" class="form-control" :name="`order[extra_items_attributes][${extra_item_index}][price]`" step="0.01" min="0.0" inputmode="numeric" :class="classes" v-model.trim="extra_item.price" @keyup="setExtraItems">
            <p class="mb-0"><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-auto" v-if="!isFixedItem(extra_item)">
          <label class="col-form-label">&nbsp;</label>
          <button class="btn btn-danger btn w-100" type="button" @click.prevent="deleteExtraItem(extra_item_index)">
          {{ I18n.t('destroy') }}
        </button>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object
    },
    order_extra_items: {
      type: Array
    },
    extra_item_categories: {
      type: Array
    }
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.extra_item'
      },
      extra_items: this.order_extra_items
    }
  },
  mounted(){
    this.adjustPrice()
  },
  methods: {
    newExtraItem(name = null){
      const new_extra_item = {
        id: null,
        category: 'discount',
        name,
        price: 0,
        _destroy: null
      }
      this.extra_items = [
        ...this.extra_items,
        new_extra_item
      ]
    },
    deleteExtraItem(index){
      this.extra_items[index]._destroy = '-1'
      this.$nextTick(() => {
        this.$forceUpdate()
        this.setExtraItems()
      })
    },
    isDeleted(extra_item){
      return extra_item._destroy == '-1'
    },
    isFixedItem(extra_item){
      return ['方案折扣', '技術支援折扣', 'Workspace Edition Discount', 'Support Plan Discount'].includes(extra_item.name)
    },
    adjustPrice(){
      if (!lodash.isEmpty(this.extra_items)){
        this.extra_items = this.extra_items.map((extra_item) => {
          let tmp_price = lodash.toNumber(extra_item.price)
          // 轉正數以方便顯示
          if(tmp_price < 0){
            tmp_price *= -1
          }
          extra_item = {
            ...extra_item,
            price: tmp_price
          }
          return extra_item
        })
      }
    },
    setExtraItems(){
      this.$emit('setExtraItems', this.extra_items.filter(({ _destroy }) =>  _destroy != '-1').map((extra_item) => {
        // 折扣乘上負項
        let tmp_price = lodash.toNumber(extra_item.price)
        if(extra_item.category == 'discount'){
          tmp_price *= -1
        }
        extra_item = {
          ...extra_item,
          price: tmp_price
        }
        return extra_item
      }))
    }
  },
  watch: {
    // order_extra_items: function(order_extra_items){
    //   this.extra_items = order_extra_items
    // }
  }
}
</script>

<style>

</style>