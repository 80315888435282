<template>
  <div :class="{ 'en-text': I18n.locale == 'en' }">
    <!-- region -->
    <input type="hidden" name='order[region]' v-model="order.region" />
    <input type="hidden" name='order[fresh_mark]' v-model="order.fresh_mark" />
    <input type="hidden" name='order[category]' v-model="order.category" />
    <input type="hidden" name='order[currency]' v-model="order.currency" />
    <ValidationObserver v-slot="{ invalid }">
    <div class="d-md-flex justify-content-between align-items-end mb-3">
      <h5>{{ I18n.t('welcome') }}</h5>
      <p class="mb-2">{{ I18n.t('exchange_rate_prefix') }} USD 1:{{ exchangeRate() }}</p>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <!-- 等待香港資料 -->
        <div class="form-group row" v-if="false">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('region', locale_scope) }}</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('region', locale_scope)" v-slot="{ errors }">
            <multiselect v-model="order.region" :options="regions" :placeholder="I18n.t('helpers.select.prompt')" :allow-empty="false">
              <template slot="singleLabel" slot-scope="{ option }"><strong>{{ lodash.upperCase(option) }}</strong></template>
            </multiselect>
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- domain -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('domain', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider vid="domain" rules="required" :name="I18n.t('domain', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="order[domain]" :class="classes" v-model.trim="order.domain" inputmode="url">
            <small class="form-text text-muted" v-html="I18n.t('simple_form.hints.order.domain')"></small>
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- company_name -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('company_name', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('company_name', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="order[company_name]" :class="classes" v-model.trim="order.company_name">
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- tax_number -->
        <div class="form-group row" v-if="order.region == 'tw'">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('tax_number', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required|numeric|length:8" :name="I18n.t('tax_number', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="order[tax_number]" :class="classes" v-model.trim="order.tax_number" inputmode="numeric">
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- address -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('address', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('address', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="order[address]" :class="classes" v-model.trim="order.address">
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- transfer_token -->
        <div class="form-group row" v-if="!order.fresh_mark && !isRenew()">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('transfer_token', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('transfer_token', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="order[transfer_token]" :class="classes" v-model.trim="order.transfer_token">
            <small class="form-text text-muted" v-html="I18n.t('simple_form.hints.order.transfer_token')"></small>
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <!-- open_at -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('open_at', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('open_at', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <flat-pickr
                v-model="date"
                :config="config"
                class="form-control form-input"
                :class="classes"
                :placeholder="I18n.t('helpers.select.prompt')"
                name="order[open_at]">
            </flat-pickr>
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- contact -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('contact', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('contact', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="order[contact]" :class="classes" v-model.trim="order.contact">
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- phone -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('phone', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('phone', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="text" class="form-control" name="order[phone]" :class="classes" v-model.trim="order.phone" inputmode="tel">
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>

        <!-- email -->
        <div class="form-group row">
          <label class="col-12 col-md-3 col-form-label">{{ I18n.t('email', locale_scope) }}*</label>
          <div class="col-12 col-md-9">
            <ValidationProvider rules="required" :name="I18n.t('email', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
            <input type="email" class="form-control" name="order[email]" :class="classes" v-model.trim="order.email" inputmode="email">
            <p><small class="text-danger">{{ errors[0] }}</small></p>
            </ValidationProvider>
          </div>
        </div>
        <template v-if="!isRenew()">
          <!-- admin_name -->
          <div class="form-group row">
            <label class="col-12 col-md-3 col-form-label">{{ I18n.t('admin_name', locale_scope) }}*</label>
            <div class="col-12 col-md-9">
              <ValidationProvider rules="required" :name="I18n.t('admin_name', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
              <input type="text" class="form-control" name="order[admin_name]" :class="classes" v-model.trim="order.admin_name">
              <small class="form-text text-muted" v-html="I18n.t('simple_form.hints.order.admin_name')"></small>
              <p><small class="text-danger">{{ errors[0] }}</small></p>
              </ValidationProvider>
            </div>
          </div>

          <!-- admin_email -->
          <div class="form-group row">
            <label class="col-12 col-md-3 col-form-label">{{ I18n.t('admin_email', locale_scope) }}*</label>
            <div class="col-12 col-md-9">
              <ValidationProvider rules="required" :name="I18n.t('admin_email', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
              <div class="input-group">
                <input type="text" class="form-control" name="order[admin_email]" :class="classes" v-model.trim="order.admin_email" @keypress="preventAtEmail">
                <span class="input-group-text">@{{ order.domain || I18n.t('domain', locale_scope) }}</span>
              </div>
              <p><small class="text-danger">{{ errors[0] }}</small></p>
              </ValidationProvider>
            </div>
          </div>

          <!-- admin_backup_email -->
          <div class="form-group mb-3 row">
            <label class="col-12 col-md-3 col-form-label">{{ I18n.t('admin_backup_email', locale_scope) }}*</label>
            <div class="col-12 col-md-9">
              <ValidationProvider :rules="{ required: true, not_contain: '@domain'}" :name="I18n.t('admin_backup_email', locale_scope)" :immediate="immediate_validate" v-slot="{ errors, classes }">
              <input type="email" class="form-control" name="order[admin_backup_email]" :class="classes" v-model.trim="order.admin_backup_email" inputmode="email">
              <small class="form-text text-muted" v-html="I18n.t('simple_form.hints.order.admin_backup_email')"></small>
              <p><small class="text-danger">{{ errors[0] }}</small></p>
              </ValidationProvider>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="form-group my-3 row no-gutters">
      <label class="col-12 col-md-2 col-form-label fw-bold">
        {{ I18n.t('order.choose_product') }}*
      </label>
      <div class="col-12 col-md-10">
        <input type="hidden" name="order[product_id]" value>
        <ValidationProvider :rules="`required|oneOf:${products.map(product => product.id).join(',')}`" :immediate="immediate_validate" :name="I18n.t('activerecord.models.product')">
        <div class="row">
          <div class="col-12 col-md-4 mb-3" v-for="(product) in grouped_products['Business']" :key="product.id">
            <div class="card" role="button" :class="{'border-primary border-2': product.id == order.product_id}">
              <div class="card-body" @click="selectProduct(product.id)">
                <h5 class="card-title text-center">{{ product.name }}</h5>
                <h6 class="card-subtitle mb-2 text-center">${{ calUnitProductPrice(product.id) }} USD</h6>
                <p class="card-text text-center"> {{ I18n.t('per_user_per_month') }} </p>
                <div class="custom-control custom-radio text-center">
                  <input type="radio" :id="`product_${product.id}`" name="order[product_id]" class="custom-control-input" :value="product.id" v-model='order.product_id' :checked="product.id == order.product_id">
                  <label :for="`product_${product.id}`" class="custom-control-label" role="button">
                    {{ I18n.t('choose') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 mb-3" v-for="(product) in grouped_products['Enterprise']" :key="product.id">
            <div class="card" role="button" :class="{'border-primary border-2': product.id == order.product_id}">
              <div class="card-body" @click="selectProduct(product.id)">
                <h5 class="card-title text-center">{{ product.name }}</h5>
                <h6 class="card-subtitle mb-2 text-center">${{ calUnitProductPrice(product.id) }} USD</h6>
                <p class="card-text text-center"> {{ I18n.t('per_user_per_month') }} </p>
                <div class="custom-control custom-radio text-center">
                  <input type="radio" :id="`product_${product.id}`" name="order[product_id]" class="custom-control-input" :value="product.id" v-model='order.product_id' :checked="product.id == order.product_id">
                  <label :for="`product_${product.id}`" class="custom-control-label" role="button">
                    {{ I18n.t('choose') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 mb-3" v-for="(product) in grouped_products['Teaching']" :key="product.id">
            <div class="card" role="button" :class="{'border-primary border-2': product.id == order.product_id}">
              <div class="card-body" @click="selectProduct(product.id)">
                <h5 class="card-title text-center">{{ product.name }}</h5>
                <h6 class="card-subtitle mb-2 text-center">${{ calUnitProductPrice(product.id) }} USD</h6>
                <p class="card-text text-center"> {{ I18n.t('per_user_per_month') }} </p>
                <p class="card-text text-center text-muted">{{ I18n.t('order.education_desc') }}</p>
                <div class="custom-control custom-radio text-center">
                  <input type="radio" :id="`product_${product.id}`" name="order[product_id]" class="custom-control-input" :value="product.id" v-model='order.product_id' :checked="product.id == order.product_id">
                  <label :for="`product_${product.id}`" class="custom-control-label" role="button">
                    {{ I18n.t('choose') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        </ValidationProvider>
      </div>
    </div>

    <!-- month_amount -->
    <div class="form-group my-3 row align-items-center no-gutters">
      <label class="col-12 col-md-2 col-form-label fw-bold" for="order_month_amount">
        {{ I18n.t(`month_amount${isRenew() ? '_to_year' : ''}`, locale_scope) }}*
      </label>
      <div class="col-12 col-md-auto">
        <ValidationProvider rules="required" :name="I18n.t(`month_amount${isRenew() ? '_to_year' : ''}`, locale_scope)" :immediate="immediate_validate" v-slot="{ errors }">
        <input type="hidden" name="order[month_amount]"  v-model="order.month_amount">
        <!-- 續約先固定1年，等金流額度提升再變更 -->
        <template v-if="isRenew()">
          <label class="col-12 col-md-2 col-form-label">
            {{ month_amount.label }}
          </label>
        </template>
        <template v-else>
          <multiselect v-model="month_amount" :options="selectable_months" :placeholder="I18n.t('helpers.select.prompt')" label="label" track-by="value" :allow-empty="false" :showLabels="false" @select="setMonthAmount"></multiselect>
        </template>
        <p class="mb-0"><small class="text-danger">{{ errors[0] }}</small></p>
        </ValidationProvider>
      </div>
    </div>

    <!-- quantity -->
    <div class="form-group my-3 row align-items-center no-gutters">
      <label class="col-12 col-md-2 col-form-label fw-bold" for="order_quantity">
        {{ I18n.t('quantity', locale_scope) }}*
      </label>
      <div class="col-12 col-md-auto">
        <ValidationProvider rules="required" :name="I18n.t('quantity', locale_scope)" :immediate="immediate_validate" v-slot="{ errors }">
        <input type="hidden" name="order[quantity]"  v-model="order.quantity">
        <multiselect v-model="order.quantity" :options="quantity_range" :placeholder="I18n.t('helpers.select.prompt')" :allow-empty="false" :showLabels="false"></multiselect>

        <p class="mb-0"><small class="text-danger">{{ errors[0] }}</small></p>
        </ValidationProvider>
      </div>
      <!-- <div class="col-12 col-md-auto" v-if="isYearly() && !isRenew()">
        <span>※{{ I18n.t('simple_form.hints.order.quantity') }}</span>
      </div> -->
    </div>

    <support-plan :order="order" :support_plans="support_plans" v-if="!lodash.isEmpty(support_plans)" :immediate_validate="immediate_validate"></support-plan>

    <extra-items :order="order" :order_extra_items="extra_items" :extra_item_categories="extra_item_categories" v-if="canEditExtraItem()" @setExtraItems="setExtraItems"></extra-items>
    <div class="table-responsive my-3">
      <table class="table border border-dark border-2">
        <thead class="border-1 border-dark">
          <tr>
            <th colspan="2">{{ I18n.t('total_price_trial') }}</th>
          </tr>
        </thead>
        <tbody class="border-1 border-dark">
          <template v-if="order.product_id && order.quantity">
            <tr >
              <th colspan="2" class="border-bottom-0">
                <ul class="m-0">
                  <li>{{ I18n.t('activerecord.models.product') }}</li>
                </ul>
              </th>
            </tr>
            <tr>
              <th class="border-bottom-0">
                <div class="mb-3 ps-5">
                  <p class="mb-0">{{ selected_product.name }}</p>
                  <p class="mb-0">{{ I18n.t(`month_amount${isRenew() ? '_to_year' : ''}`, locale_scope) }} {{ isRenew() ? `* ${I18n.t('order.month_amount', {month_amount: 12})}` : '' }}</p>
                  <p class="mb-0"> {{ I18n.t('exchange_rate', locale_scope) }} </p>
                  <p class="mb-0">{{ I18n.t('quantity', locale_scope) }}</p>
                  <p class="mb-0" v-if="discount_multiplier != 1">{{ I18n.t('discount_multiplier', locale_scope) }}</p>
                </div>
                <p class="mb-0 ps-3">{{ I18n.t('product_total', locale_scope) }}</p>
              </th>
              <td class="align-bottom text-end border-bottom-0">
                <div class="mb-3">
                  <p class="mb-0">{{ `$${calUnitProductPrice(selected_product.id)} USD` }}</p>
                  <p class="mb-0">*{{ month_amount.label }}{{ isRenew() ? ' *12' : '' }}</p>
                  <p class="mb-0">*{{ exchange_rate }} = {{ numberToCurrency(productPrice,2) }} </p>
                  <p class="mb-0">*{{order.quantity}}</p>
                  <p class="mb-0" v-if="discount_multiplier != 1">*{{ discount_multiplier*100 }}%</p>
                </div>
                =<span class="mb-0 text-decoration-underline"> {{ numberToCurrency(productTotal,2) }}</span>
              </td>
            </tr>
          </template>
          <template v-if="order.support_plan_id">
            <tr class="border-dark border-top">
              <th colspan="2" class="border-bottom-0">
                <ul class="m-0">
                  <li>{{ I18n.t('activerecord.models.support_plan') }}</li>
                </ul>
              </th>
            </tr>
            <tr v-if="!isTw() && !isYearly()">
              <th class="border-bottom-0">
                <div class="ps-5 mb-3">
                  <p class="mb-0">{{ I18n.t('support_plan.average_monthly_fee') }}</p>
                  <p class="mb-0">{{ I18n.t('month_amount', locale_scope) }}</p>
                </div>
                <p class="mb-0 ps-3">{{ selected_support_plan.name }}</p>
              </th>
              <td class="align-bottom text-end border-bottom-0">
                <div class="mb-3">
                  <p class="mb-0">{{ numberToCurrency(lodash.round((selected_support_plan.price / 12), 0),2) }}</p>
                  <p class="mb-0">*{{ order.month_amount }}</p>
                </div>
                =<span class="mb-0 text-decoration-underline"> {{ numberToCurrency(supportPlanPrice()) }}</span>
              </td>
            </tr>
            <tr v-else>
              <th class="border-bottom-0">
                <div class="ps-3">{{ selected_support_plan.name }}</div>
              </th>
              <td class="align-bottom text-end border-bottom-0">
                <span class="text-decoration-underline">{{ numberToCurrency(supportPlanPrice()) }}</span>
              </td>
            </tr>
          </template>
          <template v-if="extra_items.filter(({ price }) => price != 0).length > 0">
            <tr>
              <th colspan="2" class="border-bottom-0 border-dark border-top">
                <ul class="m-0">
                  <li>{{ I18n.t('activerecord.models.extra_item') }}</li>
                </ul>
              </th>
            </tr>
            <tr class="mb-3" v-for="(extra_item, extra_item_index) in extra_items" :key="extra_item_index">
              <template v-if="extra_item.price != 0">
                <th class="py-0 border-bottom-0">
                  <div class="ps-5"><p class="mb-0">{{ extra_item.name }}</p></div>
                </th>
                <td class="py-0 align-bottom text-end border-bottom-0">
                  <span>{{ numberToCurrency(extra_item.price) }}</span>
                </td>
              </template>
            </tr>
            <tr>
              <th class="border-bottom-0">
                <span class="ps-3">{{ I18n.t('extra_item_price', locale_scope) }}</span>
              </th>
              <td class="align-bottom text-end border-bottom-0">
                =<span class="text-decoration-underline"> {{ numberToCurrency(extra_item_price) }}</span>
              </td>
            </tr>
          </template>

          <template v-if="tax_fee > 0">
            <tr>
              <th class="ps-4 border-top border-dark border-bottom-0"> {{ I18n.t('price_before_tax', locale_scope) }} </th>
              <td class="align-bottom text-end border-top border-dark border-bottom-0">
                {{ numberToCurrency(price_before_tax) }}
              </td>
            </tr>
            <tr >
              <th class="ps-4 "> {{ I18n.t('tax') }} </th>
              <td class="align-bottom text-end ">
                * {{ taxes[order.region]*100 }}% = <span class="text-decoration-underline">{{ numberToCurrency(tax_fee) }}</span>
              </td>
            </tr>
          </template>
          <tr class="border-top border-dark">
            <th class="ps-4">Total</th>
            <td class="align-bottom text-end">
              <span class="text-decoration-double-underline">{{ numberToCurrency(totalPrice) }}</span>
              <p class="text-danger" v-if="!user_section && isTw() && totalPrice >= 200000"><b>綠界金流限制金額不得大於等於20萬</b></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-center my-3" v-if="user_section">
      <div class="col-12 col-sm-8">
        <term :order="order"></term>
        <div class="text-center mb-3">
          <ValidationProvider :rules="{ required: { allowFalse: false } }" :name="I18n.t('agree_term')" v-slot="{ errors, classes }">
          <div class="form-check mb-3">
            <div class="d-inline-block">
              <input type="checkbox" v-model="term" id="order_term" class="form-check-input check_boxes optional" :class="classes" >
              <label for="order_term" class="form-check-label collection_check_boxes">{{ I18n.t('agree_term') }}</label>
            </div>
          </div>
          <p class="mb-0"><small class="text-danger">{{ errors[0] }}</small></p>
          </ValidationProvider>
          <a href="#qa" data-bs-toggle="modal" class="text-primary">{{ I18n.t('qa') }}</a>
        </div>
        <div class="text-center mb-3">
          <p class="text-danger" v-if="isTw() && totalPrice >= 200000"><b>綠界金流限制金額不得大於等於20萬</b></p>
          <button class="btn btn-success" :disabled="invalid" :data-confirm="I18n.t('confirm_action')">{{ I18n.t('submit') }}</button>
        </div>
      </div>
    </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { MandarinTraditional } from 'flatpickr/dist/l10n/zh-tw.js';
import SupportPlan from './SupportPlan';
import ExtraItems from './ExtraItems';
import ExtraItem from './ExtraItem';
import { extend } from "vee-validate";
extend("not_contain", {
  params: ["target"],
  validate(value, { target }) {
    return !value.endsWith(target);
  },
  message: I18n.t("order.errors.admin_backup_email"),
});
import numberToCurrency from './numberToCurrency';
export default {
  components: {
    Multiselect,
    flatPickr,
    SupportPlan,
    ExtraItems,
    ExtraItem
  },
  props: {
    order_form: {
      type: Object
    },
    // 使用者顯示區域，後台隱藏
    user_section: {
      type: Boolean,
      default: true
    },
    action_name: {
      type: String,
      default: 'new'
    }
  },
  data(){
    return {
      I18n,
      lodash,
      dayjs,
      locale_scope: {
        scope: 'activerecord.attributes.order'
      },
      date: this.order_form.order.open_at,
      order: this.order_form.order,
      regions: this.order_form.regions,
      products: this.order_form.products,
      grouped_products: lodash.groupBy(this.order_form.products, ({ name }) => { return name.split(' ')[0] }),
      support_plans: this.order_form.support_plans,
      selected_product: this.order_form.products.find(({ id }) => id == this.order_form.order.product_id),
      selected_support_plan: this.order_form.support_plans.find(({ id }) => id == this.order_form.order.support_plan_id),
      productPrice: lodash.toNumber(this.order_form.order.product_price),
      productTotal: lodash.toNumber(this.order_form.order.product_total),
      exchange_rate: lodash.toNumber(this.order_form.order.exchange_rate),
      handle_fee: 0,
      price_before_tax: lodash.toNumber(this.order_form.order.price_before_tax),
      tax_fee: lodash.toNumber(this.order_form.order.tax_fee),
      totalPrice: lodash.toNumber(this.order_form.order.price),
      discount_multiplier: lodash.toNumber(this.order_form.order.quantity_discount),
      handle_fees: {
        tw: 0,
        hk: 0
      },
      taxes: {
        tw: 0.05,
        hk: 0
      },
      round_precision: {
        tw: 0,
        hk: 2
      },
      exchange_rates: this.order_form.exchange_rates,
      extra_item_categories: this.order_form.extra_item_categories,
      extra_item_price: this.order_form.order.extra_item_price,
      extra_items: this.order_form.extra_items,
      config: {
        locale: I18n.locale == 'zh-TW' ? MandarinTraditional : 'en',
        defaultDate: this.order_form.order.open_at,
        altFormat: 'Y-m-d',
        minDate: this.isRenew() ? dayjs().format() : dayjs().add(3, 'day').format(),
        maxDate: dayjs().add(2, 'month').format()
      },
      term: false,
      immediate_validate: lodash.includes(['create', 'update'], this.action_name),
      selectable_months: [],
      month_amount: {},
      quantity_range: lodash.range(1,21)
    }
  },
  mounted(){
    this.setQuantityRange()

    if(!this.order.product_id){
      this.selectProduct(this.products[0].id)
    }
    this.setExtraIPrice()
    // 處理續約轉年份顯示
    if(this.isRenew()){
      [12, 24, 36].forEach((month) => {
        this.selectable_months.push({
          label: month/12,
          value: month
        })
      })
    } else {
      lodash.range(1,13).forEach((month) => {
        this.selectable_months.push({
          label: month,
          value: month
        })
      })
    }
    this.$nextTick(() => {
      this.month_amount = this.selectable_months.find(({ value }) => value == this.order.month_amount)
    })
  },
  methods: {
    resetSelected(){
      this.selected_support_plan = null
    },
    regionChanged(){
      this.resetSelected()
    },
    selectProduct(product_id){
      this.order.product_id = product_id
    },
    setMonthAmount(item){
      this.order.month_amount = item.value
    },
    setQuantityRange(){
      if(this.isTw()){
        this.quantity_range = lodash.range(1,21)
      } else {
        this.quantity_range = lodash.range(1,301)
      }
    },
    setExtraItems(extra_items){
      this.extra_items = extra_items
      this.$nextTick(() => {
        this.setExtraIPrice()
      })
    },
    setExtraIPrice(){
      this.extra_item_price = lodash.sumBy(this.extra_items, ({price}) => {
        return lodash.toNumber(price)
      })
      this.$nextTick(this.calTotal())
    },
    numberToCurrency(number, precision){
      const region = this.order.region
      let round_precision = precision
      round_precision ||= this.round_precision[region]
      return numberToCurrency(region, number, round_precision)
    },
    exchangeRate(){
      if (this.order.id == null){
        if (this.order.region == 'tw'){
          this.exchange_rate = this.exchange_rates.twd
          return `${this.exchange_rate} TWD`
        } else {
          this.exchange_rate = this.exchange_rates.hkd
          return `${this.exchange_rate} HKD`
        }
      } else {
        if (this.order.region == 'tw'){
          this.exchange_rate = this.order.exchange_rate
          return `${this.exchange_rate} TWD`
        } else {
          this.exchange_rate = this.order.exchange_rate
          return `${this.exchange_rate} HKD`
        }
      }
    },
    calUnitProductPrice(product_id){
      const product = this.products.find(({id}) =>  id === product_id )
      let price =  lodash.toNumber(product.price) // * this.order.month_amount
      if(this.order.fresh_mark && this.isYearly() && this.isTw()){
        price *= product.fresh_discount
      }
      return price
    },
    calProductPrice(product_id){
      let price =  lodash.toNumber(this.calUnitProductPrice(product_id))
      price *= this.order.month_amount
      price = lodash.round((price * this.exchange_rate), this.round_precision[this.order.region])
      this.productPrice = price
    },
    supportPlanPrice(){
      let support_plan_price = this.selected_support_plan ? lodash.toNumber(this.selected_support_plan.price) : 0
      if(!this.isTw() && !this.isYearly()){
        support_plan_price = lodash.round((support_plan_price/12), 0) * this.order.month_amount
      }
      return lodash.round(support_plan_price, 0)
    },
    calDiscountMultiplier(){
      this.discount_multiplier = 1
      // 移除u數折扣
      // if(this.isYearly() && !this.isRenew()){
      //   const quantity = this.order.quantity
      //   if(this.isTw()){
      //     switch (true) {
      //       case 1 <= quantity && quantity <= 10:
      //         this.discount_multiplier = 0.99
      //         break;
      //       case 11 <= quantity && quantity <= 15:
      //         this.discount_multiplier = 0.98
      //         break;
      //       case 16 <= quantity && quantity <= 20:
      //         this.discount_multiplier = 0.97
      //         break;
      //       default:
      //         break;
      //     }
      //   } else {
      //     switch (true) {
      //       case 1 <= quantity && quantity <= 20:
      //         this.discount_multiplier = 0.98
      //         break;
      //       case 21 <= quantity && quantity <= 50:
      //         this.discount_multiplier = 0.97
      //         break;
      //       case 51 <= quantity && quantity <= 100:
      //         this.discount_multiplier = 0.96
      //         break;
      //       case 101 <= quantity:
      //         this.discount_multiplier = 0.95
      //         break
      //       default:
      //         break;
      //     }
      //   }
      // } else {
      //   this.discount_multiplier = 1
      // }
    },
    calProductTotal(){
      this.productTotal = lodash.round((this.productPrice * this.discount_multiplier * this.order.quantity), this.round_precision[this.order.region])
      this.$nextTick(this.calTotal())
    },
    calTotal(){
      this.$nextTick(() => {
        this.price_before_tax = lodash.round(this.productTotal + this.supportPlanPrice() + this.extra_item_price, this.round_precision[this.order.region])
        this.handle_fee = this.handle_fees[this.order.region]
        this.tax_fee = lodash.round(this.price_before_tax*this.taxes[this.order.region], this.round_precision[this.order.region])
        this.totalPrice = this.price_before_tax + this.tax_fee
      })
    },
    preventAtEmail(event) {
      if(event.key == '@'){
        event.preventDefault();
      }
    },
    canEditExtraItem(){
      if(this.user_section){
        return false
      }
      return ['waiting', 'pending'].includes(this.order.status)
    },
    showExtraItemRow(extra_item){
      if(this.user_section){
        return !lodash.isEmpty(this.extra_items) && lodash.toNumber(extra_item.price) != 0
      }
      return true
    },
    isYearly(){
      return this.order.month_amount == 12
    },
    isRenew(){
      return this.order_form.order.category == 'renew'
    },
    isTw(){
      return this.order.region == 'tw'
    }
  },
  watch: {
    'order.region': function(new_region){
      this.regionChanged(new_region)
    },
    'order.product_id': function(product_id){
      this.selected_product = this.products.find(({id}) =>  id === product_id )
      this.calProductPrice(product_id)
      this.$nextTick(this.calProductTotal())
    },
    'order.month_amount': function(){
      this.calProductPrice(this.selected_product.id)
      this.calDiscountMultiplier()
      this.$nextTick(this.calProductTotal())
    },
    'order.quantity': function(){
      this.calDiscountMultiplier()
      this.$nextTick(this.calProductTotal())
    },
    'order.support_plan_id': function(){
      this.selected_support_plan = this.support_plans.find(({id}) =>  id === this.order.support_plan_id )
      this.$nextTick(this.calTotal())
    }
  }
}
</script>

<style>
  .form-control.flatpickr-input, .form-input.form-control[readonly] {
    background-color: #fff;
  }
</style>